



























































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    }),
    titleList() {
      return [
        this.$t("cybercoin.shortTitle01"),
        this.$t("cybercoin.shortTitle02"),
        this.$t("cybercoin.shortTitle03"),
        this.$t("cybercoin.shortTitle04"),
        this.$t("cybercoin.shortTitle05"),
        this.$t("cybercoin.shortTitle06"),
        this.$t("cybercoin.shortTitle07"),
        this.$t("joinUs"),
        "FOOTER"
      ];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cybercoin);
    }
  }
});
